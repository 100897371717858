@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

$primaryCream: #fffee7;
$primaryTeal: #00b3c5;
$red: #f00;
$secondaryYellow: #ffb500;
$borderWidth: 8px;

//new palette 18.11.2021
$primaryBlue: #3460ad;
$secondaryBlue: #91adde;
$softBlue: #ebf1ff;

$darkestMint: #0a5c51;
$midMint: #14b8a2;
$brightMint: #29e3ca;
$lightMint: #ebfffc;

$defaultBlack: #11121a;
$white: #ffffff;

html,
body,
#root {
    background-color: $white;
    color: $defaultBlack;
    height: 100%;
    min-height: 100vh;
    overscroll-behavior: none;
}

html::-webkit-scrollbar {
    border: none;
    width: 8px;
}

html::-webkit-scrollbar-thumb {
    background-color: #7253cc;
    border-radius: 4px;
}

html::-webkit-scrollbar-track {
    border: none;
}

html::-webkit-scrollbar {
    border: none;
    width: 8px;
}

body::-webkit-scrollbar-thumb {
    background-color: #7253cc;
    border-radius: 4px;
}

body::-webkit-scrollbar-track {
    border: none;
}

#root::-webkit-scrollbar {
    border: none;
    width: 8px;
}

#root::-webkit-scrollbar-thumb {
    background-color: #7253cc;
    border-radius: 4px;
}

#root::-webkit-scrollbar-track {
    border: none;
}

body {
    margin: 0;
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$text: #767676;

.hide {
    display: none;
}

.picker-container .picker-column {
    flex: auto !important;
}

.css-3pxmmn {
    background-color: transparent !important;
    overflow: hidden;
    display: flex;
    align-items: flex-end !important;
}

.ReactModalPortal {
    position: relative;
    z-index: 20;
}

.app-content {
    background-color: $white;
    height: 100%;
}

.app-content::-webkit-scrollbar {
    border: none;
    width: 8px;
}

.app-content::-webkit-scrollbar-thumb {
    background-color: #7253cc;
    border-radius: 4px;
}

.app-content::-webkit-scrollbar-track {
    border: none;
}

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '../node_modules/react-tabs/style/react-tabs.scss';
@import 'styles/reset.scss';
@import 'js/pages/Assessment/DeadlineDate/datePicker.scss';
